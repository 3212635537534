import React, { useContext, useEffect, useState } from "react";
import NDISUtilisationContext from "./context";
import { Spacer, ZenCard } from "components/common";
import { LabelLarge, ParagraphSmall } from "baseui/typography";
import { useStyletron } from "baseui";
import { ZenDatePicker } from "components/zenComponents/zenTime";
import { ZenButton } from "components/zenComponents/zenButtons";
import { ZenSelect } from "./components/ndisUtilisationSelect";
import { FormControl } from "baseui/form-control";
import { Notification, KIND } from "baseui/notification";
import { ErrorNotification } from "components/errorBox";
import { Value } from "baseui/select";
import { useForm } from "react-hook-form";

interface FormValues {
  startDate: string;
  endDate: string;
  contact_area: { id: string; label: string }[];
  client: { id: string; label: string }[];
  isIncludedInUtilisation: { id: string; label: string }[];
  prc_support_code: { id: string; label: string }[];
  core_supports: { id: string; label: string }[];
  support_coordination: { id: string; label: string }[];
}

const isIncludedOptions: Value = [
  { id: "true", label: "Yes" },
  { id: "false", label: "No" },
];

const NDISUtilisationView = () => {
  const [css] = useStyletron();
  const card = css({ width: "fit-content" });
  const dates = css({ display: "flex", width: "555px" });
  const buttons = css({ display: "flex", justifyContent: "flex-end", marginTop: "15px" });
  const cssDiv = css({ width: "300%", height: "fit-content" });

  const { errorMsg, values, actions } = useContext(NDISUtilisationContext);
  const {
    contractAreaOpt,
    clientOpt, // Fetch client options here
    durationWarning,
    loading,
    prcSupportLoading,
    coreSupportItemsLoading,
    supportCoordinationItemsLoading,
    ndisUtilisationPayloadError,
  } = values;

  const { handleOnSubmit, setContractArea } = actions;

  const { control, handleSubmit, formState: { errors } } = useForm<FormValues>({
    defaultValues: {
      startDate: "",
      endDate: "",
      contact_area: [],
      client: [], // Add client as an empty array
      isIncludedInUtilisation: [{ id: "true", label: "Yes" }],
      prc_support_code: [],
      core_supports: [],
      support_coordination: [],
    },
  });

  const [prcOpt, setPrcOpt] = useState<{ id: string; label: string }[]>([]);
  const [coreSupportOpt, setCoreSupportOpt] = useState<{ id: string; label: string }[]>([]);
  const [supportCoordOpt, setSupportCoordOpt] = useState<{ id: string; label: string }[]>([]);

  useEffect(() => {
    fetch('/api/ndis_plans/ndis_line_items/prc_support/options', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ search: '' })
    })
      .then(res => res.json())
      .then(data => setPrcOpt(data))
      .catch(err => console.error("Error fetching PRC options:", err));

      // Fetch Core Support options
      fetch("/api/ndis_plans/ndis_line_items/core_support/search/options", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ search: "" }),
      })
        .then((res) => res.json())
        .then((data) => setCoreSupportOpt(data))
        .catch((err) => console.error("Error fetching Core Support options:", err));


    fetch('/api/ndis_plans/ndis_line_items/support_coordination/search/options', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ search: '' })
    })
      .then(res => res.json())
      .then(data => setSupportCoordOpt(data))
      .catch(err => console.error("Error fetching Support Coord options:", err));
  }, []);

  const prcOptions: Value = [{ id: "all", label: "All" }, ...prcOpt];
  const coreSupportOptions: Value = [{ id: "all", label: "All" }, ...coreSupportOpt];
  const supportCoordOptions: Value = [{ id: "all", label: "All" }, ...supportCoordOpt];

  const fixAllSelections = (data: FormValues) => {
    const replaceAllWithFullArray = (
      array: { id: string; label: string }[],
      fullOpt: { id: string; label: string }[]
    ) => {
      if (!array) return [];
      const hasAll = array.some(item => item.id === 'all');
      if (!hasAll) return array;
      return fullOpt;
    };

    data.prc_support_code = replaceAllWithFullArray(data.prc_support_code, prcOpt);
    data.core_supports = replaceAllWithFullArray(data.core_supports, coreSupportOpt);
    data.support_coordination = replaceAllWithFullArray(data.support_coordination, supportCoordOpt);

    return data;
  };

  const onSubmit = (data: FormValues) => {
    console.log("Form Data on Submit before fix:", data);
    const fixedData = fixAllSelections(data);
    console.log("Form Data on Submit after fix:", fixedData);

    handleOnSubmit(fixedData);
  };

  return (
    <ZenCard className={card}>
      <LabelLarge>NDIS Utilisation Report</LabelLarge>
      <ParagraphSmall maxWidth={"400px"}>
        Create NDIS utilisation report here
      </ParagraphSmall>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <div className={dates}>
          <div className={cssDiv}>
            <ZenDatePicker
              nullDefaultValue={true}
              label={"Start Date"}
              formName={"startDate"}
              formRef={control}
              inputError={errors.startDate}
              formRules={{ required: "You must select a start date." }}
            />
          </div>
          <Spacer style={{ width: "15px" }} />
          <div className={cssDiv}>
            {/* End Date can be implemented later */}
          </div>
        </div>

        <ZenSelect
          multi={false}
          options={contractAreaOpt}
          label="Contract Area (Optional)"
          formName="contact_area"
          formRef={control}
          actionOnChange={(e: Value) => setContractArea(e?.[0]?.label)}
        />

		 {/* Client Dropdown */}
		 <ZenSelect
          multi={false}
          options={clientOpt}
          label="Client (Optional)"
          formName="client"
          formRef={control}
          inputError={errors.client}
        />

        <ZenSelect
          multi={true}
          options={prcOptions}
          isLoading={prcSupportLoading}
          label="PRC Support Code"
          formName="prc_support_code"
          formRef={control}
        />

        <ZenSelect
          multi={true}
          options={coreSupportOptions}
          isLoading={coreSupportItemsLoading}
          label="Core Supports"
          formName="core_supports"
          formRef={control}
        />

        <ZenSelect
          multi={true}
          options={supportCoordOptions}
          isLoading={supportCoordinationItemsLoading}
          label="Support Coordination"
          formName="support_coordination"
          formRef={control}
        />

        <ZenSelect
          multi={false}
          options={isIncludedOptions}
          label="Include in Utilisation"
          formRef={control}
          formName="isIncludedInUtilisation"
          formRules={{ required: "Please select if included in utilisation." }}
        />

        <FormControl error={errors.client}>
          <div />
        </FormControl>
        {durationWarning && <Notification kind={KIND.warning}>{durationWarning}</Notification>}
        {ndisUtilisationPayloadError && <ErrorNotification message={errorMsg["eblobErr"]} />}
        <div className={buttons}>
          <ZenButton type={"submit"} isLoading={loading}>
            Create Report
          </ZenButton>
        </div>
      </form>
    </ZenCard>
  );
};

export default NDISUtilisationView;
